import React, { memo } from 'react'
import { graphql } from 'gatsby'

import {
  InfoPageQuery,
  // @ts-ignore
} from '__generated__/gatsby-types'

import GeneralPage from './layouts/GeneralPage'
import ContentComponents from 'components/molecules/contentComponents/ContentComponents'

interface Props {
  data: InfoPageQuery
}

const InfoPage = ({ data }: Props) => {
  const d = data.datoCmsInfoPage

  let boxProps = {
    pb: { xs: 6, md: 8, lg: 10, xl: 13 },
  }
  const lastComponent = d.contentComponents[d.contentComponents.length - 1]
  if (
    lastComponent.model.apiKey === 'manual_landing_section' ||
    lastComponent.model.apiKey === 'donor_campaigns_landing_section' ||
    lastComponent.model.apiKey === 'team_section'
  ) {
    boxProps = {
      pb: 0,
    }
  }

  let imageLandscape = d.imageLandscape.gatsbyImageData
  let imagePortrait = d.imagePortrait.gatsbyImageData

  if (d.isLevelThree) {
    imageLandscape = d.imageSmallLandscape.gatsbyImageData
    imagePortrait = d.imageSmallPortrait.gatsbyImageData
  }

  return (
    <GeneralPage
      keyId={d.originalId}
      imageLandscape={imageLandscape}
      imagePortrait={imagePortrait}
      heading={d.headingNode.childMarkdownRemark.html}
      seoMetaTags={d.seoMetaTags}
      levelThree={d.isLevelThree}
    >
      <ContentComponents
        boxProps={boxProps}
        components={d.contentComponents}
        containedWidth="wide"
      />
    </GeneralPage>
  )
}

export default memo(InfoPage)

export const pageQuery = graphql`
  query InfoPageQuery($originalId: String!) {
    datoCmsInfoPage(originalId: { eq: $originalId }) {
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headingNode {
        childMarkdownRemark {
          html
        }
      }
      isLevelThree
      contentComponents {
        ... on DatoCmsHeading {
          ...contentHeading
        }
        ... on DatoCmsDocument {
          ...contentDocument
        }
        ... on DatoCmsSubHeading {
          ...contentSubHeading
        }
        ... on DatoCmsText {
          ...contentText
        }
        ... on DatoCmsImage {
          ...contentImage
        }
        ... on DatoCmsYoutubeVideo {
          ...contentYouTube
        }
        ... on DatoCmsButton {
          ...button
        }
        ... on DatoCmsButtonGroup {
          ...contentButtonGroup
        }
        ... on DatoCmsLeadText {
          ...contentLeadText
        }
        ... on DatoCmsQuote {
          ...contentQuote
        }
        ... on DatoCmsSimpleQuote {
          ...contentSimpleQuote
        }
        ... on DatoCmsImageTextSection {
          ...contentImageTextSection
        }
        ... on DatoCmsImageLeadTextSection {
          ...contentImageLeadTextSection
        }
        ... on DatoCmsManualLandingSection {
          ...contentManualLandingSection
        }
        ... on DatoCmsTeamSection {
          ...contentTeamSection
        }
        ... on DatoCmsTextSection {
          ...contentTextSection
        }
        ... on DatoCmsDonorCampaignsLandingSection {
          ...contentDonorCampaignsLandingSection
        }
      }
      imageLandscape: heroImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
        )
      }
      imagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "1:1.4", fit: "crop", crop: "focalpoint" }
        )
      }
      imageSmallLandscape: heroImage {
        gatsbyImageData(
          width: 800
          layout: FULL_WIDTH
          imgixParams: { ar: "1:1", fit: "crop", crop: "focalpoint" }
        )
      }
      imageSmallPortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "1:2", fit: "crop", crop: "focalpoint" }
        )
      }
    }
  }
`
